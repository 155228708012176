<button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
  <mat-icon>menu</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openImportDialog()">
    <mat-icon>cloud_download</mat-icon>
    <span>Import Data...</span>
  </button>
  <button mat-menu-item (click)="openExportDialog()">
    <mat-icon>cloud_upload</mat-icon>
    <span>Export Data...</span>
  </button>
</mat-menu>
