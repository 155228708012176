<div class="container">
  <header>
    <mat-toolbar>
      <app-doc-list-menu></app-doc-list-menu>
      {{ docItems?.length }} Scroll Items
      <button mat-mini-fab color="primary" (click)="onAddNewClicked()" matTooltip="Add new document">
        <mat-icon>note_add</mat-icon>
      </button>
    </mat-toolbar>
    <mat-divider></mat-divider>
  </header>
  <div class="container content-section">
    <mat-selection-list #docList [multiple]="false" (selectionChange)="onRowClicked($event)">
      <mat-list-option *ngFor="let docItem of docItems" [value]="docItem">
        {{ docItem.title }}
      </mat-list-option>
    </mat-selection-list>
    <!-- <mat-list *ngFor="let docItem of docItems">
      <mat-list-item (click)="onRowClicked(docItem)"> {{ docItem.title }} </mat-list-item>
     </mat-list>
    <div *ngFor="let docItem of docItems">
      <div class="container-fluid doc-item">
        <div class="row" (click)="onRowClicked(docItem)">
          <div class="col-10">
            {{ docItem.title }}
          </div>
          <div class="col-1 text-right">
            <img src="../../../assets/svg/trash-icon.svg" (click)="onDeleteClicked($event, docItem)">
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
