<div class="container">
  <main class=container>
    <div class="sidebar">
      <app-doc-list #docListComponent></app-doc-list>
    </div>
    <div>
      <app-current-doc [docListComponent]="docListComponent"></app-current-doc>
    </div>
  </main>
  <footer>
    {{ title }} | v{{ version }}
  </footer>
</div>
<router-outlet></router-outlet>
