<div class="container">
  <header>
    <mat-toolbar *ngIf="docItem">
      <mat-slide-toggle [(ngModel)]="isActive" (change)="onAutoScrollClicked()">Auto Scroll</mat-slide-toggle>
      <mat-slider thumbLabel [displayWith]="formatLabel" tickInterval="1" step="1" min="1" max="1000" [(ngModel)]="speed"
        (change)="onSpeedChanged($event)">
      </mat-slider>
      <button mat-mini-fab color="primary" (click)="onEditClick()" *ngIf="!isEditing" matTooltip="Edit document">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="onUndoEditClick()" *ngIf="isEditing"
        matTooltip="Cancel document editing">
        <mat-icon>undo</mat-icon>
      </button>
      <button mat-mini-fab color="warn" (click)="onDeleteClick()" *ngIf="!isEditing" matTooltip="Delete document">
        <mat-icon>delete_forever</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="onSaveClicked()" *ngIf="isEditing" matTooltip="Save changes">
        <mat-icon>save</mat-icon>
      </button>
    </mat-toolbar>
    <div>
      {{debugInfo}}
    </div>
    <mat-divider></mat-divider>
  </header>
  <div #scrolledDocContainer class="container content-section" *ngIf="!isEditing">
    <!-- Read-only section -->
    <p>{{ docItem?.title }}</p>
    <p>{{ docItem?.textContent }}</p>

  </div>
  <div #scrolledDocContainer class="container content-section" [hidden]="!isEditing">
    <!-- Editing section -->
    <p>
      <mat-form-field appearance="fill">
        <mat-label>Title</mat-label>
        <input matInput placeholder="Enter document title..." #newTitle>
      </mat-form-field>
    </p>
    <div class="container-row flex-grow-1">
      <mat-form-field class="h-100 w-100">
        <mat-label>Text Content</mat-label>
        <textarea matInput placeholder="Enter document text..." #newTextContent></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
